<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"

    >
        <template #modal-title>
            {{action == 'editing'?$t('label_edit'):($t('label_add') + ' ' + $t('label_department').toLowerCase())}}
        </template>
        <template #default="{ hide }">


            <validation-observer
                    tag="form"
                    class="p-1"
                    #default="{handleSubmit, reset, invalid}"
                    :ref="PREFIX + '_FORM'"

            >

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_name')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_name')"

                    >
                        <b-form-input

                                v-model="itemData.name"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_name')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        v-if="!itemData.is_main"
                        #default="validationProps"
                        :name="$t('label_parent_department')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_parent_department')"

                    >
                        <v-select
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                v-model="itemData.parent_id"
                                :options="departments"
                                label="id"
                                :placeholder="$t('label_select')"
                                :reduce="val => val.id"
                                :close-on-select="false"

                        >
                            <template v-slot:option="option">

                                <span class="">{{ option.name }}</span>
                            </template>
                            <template v-slot:selected-option="option">

                                <span class="">{{ option.name}}</span>
                            </template>
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        v-if="!itemData.is_main"
                        #default="validationProps"
                        :name="$t('label_department_leader')"
                        rules=""
                        slim
                >
                    <b-form-group :label="$t('label_department_leader')"
                    >

                        <infinity-scroll
                                :query-params="{expectUsersIds : [1]}"
                                selected-prop="id"
                                :multiple="false"
                                url="/user"
                                :placeholder="$t('label_select')"
                                v-model="itemData.leader_id"
                                :close-on-select="false"
                                :default-selection="chosenLeader"

                        >
                            <!--:default-selection="chosenRelDocs"-->
                            <template #label="{item}">{{ item.name }}</template>

                        </infinity-scroll>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>

                <validation-provider
                        v-if="!itemData.is_main"
                        #default="validationProps"
                        :name="$t('label_department_users')"
                        rules=""
                        slim
                >
                    <b-form-group :label="$t('label_department_users')"
                    >

                        <infinity-scroll
                                :query-params="{expectUsersIds : [1]}"
                                selected-prop="id"
                                :multiple="true"
                                url="/user"
                                :placeholder="$t('label_select')"
                                v-model="itemData.department_users"
                                :close-on-select="false"
                                :default-selection="chosenUsers"
                        >

                            <template #label="{item}">{{ item.name }}</template>

                        </infinity-scroll>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>


                <div class="d-flex justify-content-end mt-2">
                    <b-button v-if="action == 'editing' && !itemData.is_main" variant="outline-danger"
                              @click="deleteDepartment(itemData.id)"
                              class="mr-auto btn-icon"
                    >
                        <feather-icon

                                size="18"
                                icon="Trash2Icon"
                        />
                    </b-button>
                    <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">
                        {{$t('label_close')}}
                    </button>

                    <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                            class="btn btn-primary waves-effect waves-light">{{(action ==
                        'editing')?$t('label_save'):$t('label_add')}}
                    </button>
                </div>

            </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import  infinityScroll from '@/views/components/infinityScroll'
    import vSelect from 'vue-select'
    import {
        BFormTextarea,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BButton,
        BInputGroupPrepend,
    } from 'bootstrap-vue'

    import {MODULE_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            BInputGroupPrepend,
            BButton,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            infinityScroll
        },
        props: ['editedItem', 'mainDepartment'],
        data() {
            return {
                PREFIX,

                action: 'adding',

                blankItemData: {
                    name: '',
                    parent_id: null,
                    leader_id: null,
                    department_users: []
                },

                itemData: {},

                departments: [],
                chosenLeader:false,
                chosenParentDepartment:false,
                chosenUsers:[],
                mainDep: {}
            }
        },
        computed:{
            main_department: function(){
                return this.mainDepartment? this.mainDepartment : this.mainDep
            },
        },
        methods: {
            initData() {

                this.async('get', '/' + this.PREFIX + 's', {}, function (resp) {
                    this.departments = resp.data.departments;
                    this.departments.unshift(this.main_department);

                if (this.editedItem) {
                    this.action = 'editing';
                    let item = Object.assign({}, this.blankItemData);
                    // console.log('this.editedItem',this.editedItem);

                    if(this.editedItem.id == 0) {
                        item.is_main = true;
                        item.name = this.editedItem.name;
                    } else {
                        item.is_main = false;
                        item.id = this.editedItem.id;

                        for (let prop in item) {
                            if (this.editedItem.hasOwnProperty(prop)) {
                                item[prop] = this.editedItem[prop];
                            }
                        }
                        item.department_users = this.editedItem.users.pluck('id');

                        this.chosenLeader = this.editedItem.leader;
                        this.chosenParentDepartment = this.editedItem.leader;
                        this.chosenUsers = this.editedItem.users;
                    }
                    this.itemData = item;

                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData);

                }
            });
            },
            resetForm() {
                this.itemData = Object.assign({}, this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit() {
                // console.log('this.tem',this.itemData.is_main);
                if (this.action == 'adding') {
                    this.async('post', '/' + this.PREFIX + 's', this.itemData, function (resp) {
                        this.$emit('item-added', resp.data.item);
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

                } else {
                    if(this.itemData.is_main) {
                        this.async('put', '/general_settings/1', {main_department: this.itemData.name}, function (resp) {
                            this.$emit('item-edited', {});
                            this.$bvModal.hide(this.PREFIX + '-modal');
                        });

                    } else {

                        this.async('put', '/' + this.PREFIX + 's/' + this.itemData.id, this.itemData, function (resp) {
                            this.$emit('item-edited', {});
                            this.$bvModal.hide(this.PREFIX + '-modal');
                        });
                    }
                }

            },
            getMainDepartment(){
                this.async('get', '/general_settings', {params:{}}, function (resp) {
                    let mainDepartmentName = resp.data.main_department;
                    this.mainDep = {id:'0',name: mainDepartmentName, is_main:true, level:0, parent_id:0, users:[]};
                });
            },
            deleteDepartment(id){
                this.confirmDeleting((result) => {
                    this.async('delete',this.PREFIX + 's/' + id, {params: {}}, function (resp) {
                        // this.refreshDataTable();
                        this.$bvModal.hide(this.PREFIX + '-modal');
                        this.$emit('item-deleted', {})
                    });
                })
            }
        },
        created(){
            if(!this.mainDepartment){
                this.getMainDepartment();
            }
        }

    }
</script>
