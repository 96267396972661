<template>
    <div>
        <div v-if="structureData[key] && structureData[key].length > 0"
             v-for="groupData,key in structureData" :key="key + (new Date().getTime())">


            <!--****************************first level*********************-->
            <div  v-if="key == 0" :class="(dataStructureLevel == 1 && structureData[0].length < 4)? 'text-left': 'text-center'" class="main-department" style="width:100%;">
                <department
                        @edit-item="editItem($event)"
                        data-structure-level="1"
                        @item-edited="$emit('item-edited', {})"
                        :item="structureData[key][0]"
                        level="1"
                ></department>
            </div>

            <!--****************************second level*********************-->
            <template v-else-if="key == 1">

                <department v-if="structureData[key].length == 1"
                            :style="{'margin-top': '-9px'}"
                            per-first-element="0"
                            :item="groupData[0]"
                            level="2"
                            ind="0"
                            @edit-item="editItem($event)"
                            @item-edited="$emit('item-edited', {})"
                            :third-lvl-structure="structureData[2]"
                            :forth-lvl-structure="structureData[3]"
                            :data-structure-level="dataStructureLevel"
                ></department>

                <div v-else style="justify-content: flex-end; display: flex">
                    <department v-for="item, ind in groupData"
                                :per-first-element="perFirstElement(structureData[key].length)"
                                :key="item.id"
                                :item="item"
                                :ind="ind"
                                level="2"
                                @edit-item="editItem($event)"
                                @item-edited="$emit('item-edited', {})"
                                :third-lvl-structure="structureData[2]"
                                :forth-lvl-structure="structureData[3]"
                                :data-structure-level="dataStructureLevel"
                    ></department>
                </div>

            </template>

        </div>

    </div>
</template>

<script>

    import {MODULE_PREFIX as PREFIX, MODULE_PREFIX} from './../moduleHelper'
    import { ref } from '@vue/composition-api'

    export default {

        // name: "department-lvl-structure",

        props:[
            'structureData',
            'dataStructureLevel'
        ],

        beforeCreate: function () {
            this.$options.components.department = require('./departmentSection').default
        },

        data() {
            return {
                MODULE_PREFIX,
                PREFIX,

            }
        },

        methods:{
            perFirstElement(countCardsInRow){
                let perFirstElement = 0;

                if (countCardsInRow % 2 == 0) {
                    perFirstElement = countCardsInRow / 2;
                } else {
                    perFirstElement = (countCardsInRow - countCardsInRow % 2) / 2;
                }

                return perFirstElement;
            },
            editItem(item){
                this.$root.$refs['dep-root'].editItem(item);
            },


        }


    }
</script>