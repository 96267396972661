import ability from './../../libs/acl/ability'

export function resolveRouteToRedirectUser(user, returnRoute)
{
    let route = false;
    switch(parseInt(user.userType)){
        case 1:
            if(user.id != 1){
                route = 'admin-details';
            }
            break;
        case 2: route = 'agent-details';  break;
        case 3: route = 'solicitor-details';  break;
        // case 4: route = 'client';  break;
        case 5: route = 'consultant-details';  break;
        case 6: route = 'accountant-details';  break;
        case 7: route = 'secretary-details';  break;
    }

    if(returnRoute) {
        return route? { name: route, params: { id: user.id } } : {};
    } else {
        if(route)
            this.$router.push({ name: route, params: { id: user.id } });
    }

}
export function resolveTaskRoute(task)
{

    let route = false;

    switch(task.type){
        case 'contract_registry':
            if(ability.can('view', 'contractregistry.tab_details')){
                route = {name: 'agreement_task_details', params: {id:task.id}};
            }
            break;
        case 'agreement':
            if(task.deal_id){
                if(ability.can('view', 'deal.tab_tasks')){
                    route = {name: 'agreement_task_details', params: {id:task.id}};
                }
            } else {
                if(ability.can('view', 'agreement.tab_tasks')){
                    route = {name: 'agreement_task_details', params: {id:task.id}};
                }
            }

            break;
    }

    return route;
}

export const userPermissions = [

    {label: ['label_undecided_mail'], alias: 'perm_undecided_mail'},
    {label: ['label_incoming_mail'], alias: 'perm_incoming_mail'},
    {label: ['label_outcoming_mail'], alias: 'perm_outcoming_mail'},
    {label: ['label_contact_notes'], alias: 'perm_contact_notes'},
    {label: ['label_working_time'], alias: 'perm_working_time'},
    // {label: ['label_meetings_for_today'], alias: 'perm_daily_meetings'},
    {label: ['label_meetings_for_today'], alias: 'perm_daily_meetings'},
    {label: ['label_tasks'], alias: 'perm_tasks'},
    {label: ['label_task_comments'], alias: 'perm_task_comments'},
    {label: ['label_deals_activity'], alias: 'perm_last_deal_activity'},
    {label: ['label_last_seen_deals'], alias: 'perm_last_seen_deals'},
    {label: ['label_not_assigned_agrm_deals', 'label_view'], alias: 'perm_not_assigned_agrms_view'},
    {label: ['label_not_assigned_agrm_deals', 'label_edit'], alias: 'perm_not_assigned_agrms_edit'},
    {label: ['label_perm_contacts'], alias: 'perm_contacts'},

];

export const defaultCountryId = '1';
export const defaultLanguageId = '2';