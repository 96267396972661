<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_departments')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card no-body>

            <b-card-body>

                <div class="d-flex flex-wrap   px-2 py-1 align-items-center">
                    <h5 class="mb-0">
                        {{$t('label_departments').ucFirst()}}
                    </h5>
                   <div style="padding: 0 7px; margin-left: auto;">
                       <b-button style="padding: 11px; margin-right: 3px;" class="ml-auto" variant="outline-primary"  @click="scaleUp()">+</b-button>
                       <b-button style="padding: 11px;" class="ml-auto" variant="outline-primary"  @click="scaleDown()">-</b-button>
                   </div>
                    <b-button  variant="outline-primary"  @click="editedItem = false;$bvModal.show(PREFIX + '-modal')">
                        {{$t('label_departments_create')}}
                    </b-button>
                </div>


                <div class="row" style=" display: flex">

                    <div class="col-md-12" id="structure-draggable">

                        <div ref="scaled-wrap" class="depart-wrap" :data-structure-level="dataStructureLevel">
                            <!-- structure-->

                           <department-lvl-structure @item-edited="getPageData()" :structure-data="structureData" data-structure-level="1"></department-lvl-structure>

                        </div>

                    </div>

                </div>
            </b-card-body>
        </b-card>

        <process-item-modal
                :main-department="mainDepartment"
                @item-edited="getPageData()"
                @item-deleted="getPageData()"
                @item-added="getPageData()"
                :edited-item="editedItem"
        ></process-item-modal>

    </div>
</template>

<script>

    import {
         BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton, BPopover, BAvatar,

    } from 'bootstrap-vue'

    import processItemModal from './modals/processItemModal'
    import departmentLvlStructure from './includes/structureLvl'

    import {MODULE_PREFIX as PREFIX, MODULE_PREFIX} from './moduleHelper'

    import store from '@/store'
    import {onUnmounted, onMounted} from '@vue/composition-api'
    import moduleDynamicStore from './moduleDynamicStore'
    import {$themeConfig} from '@themeConfig'

    export default {

        components: {

            BCardText,
            BCard, BCardHeader, BCardBody,
            BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
            departmentLvlStructure,
            processItemModal,
            // UseDraggable,
            // OnClickOutside

        },
        data() {
            return {
                MODULE_PREFIX,
                PREFIX,

                editedItem: false,
                dataStructureLevel: 1,
                structureData: {},
                mainDepartment: '',
                scale:1
            }
        },
        setup() {

            if (!store.hasModule(MODULE_PREFIX)) {
                store.registerModule(MODULE_PREFIX, moduleDynamicStore)
            }

            onUnmounted(() => {
                if (store.hasModule(MODULE_PREFIX)) store.unregisterModule(MODULE_PREFIX)
            })

        },
        created() {
            this.getPageData();
            this.$root.$refs['dep-root'] = this;

        },

        beforeDestroy(){
            delete this.$root.$refs['dep-root'];
        },
        methods:{

           async getPageData(){
               this.async('get', '/general_settings', {params:{}}, function (resp) {
                    let mainDepartmentName = resp.data.main_department;
                   this.mainDepartment = {id:'0',name: mainDepartmentName, is_main:true, level:0, parent_id:0, users:[]};
                   this.async('get', '/' + MODULE_PREFIX + 's' , {params:{}}, function(res){
                       // this.departments = res.data.departments;

                       this.$store.state[this.MODULE_PREFIX].structure_data = res.data.departmentsMlM;

                       let department_data = res.data.departmentsMlM;
                       department_data['lvl_0'] = [this.mainDepartment];

                       this.structureData = {
                           '0': department_data['lvl_0'],
                           '1': department_data['lvl_1'],
                           '2': department_data['lvl_2'],
                           '3': department_data['lvl_3']
                       };
                       // console.log('structureData',this.structureData);
                   });
                });

            },
            editItem(item){
                this.editedItem = item; this.$bvModal.show(this.PREFIX + '-modal');
            },
            scaleUp() {
                let el = this.$refs['scaled-wrap'];
               if(this.scale < 3) {
                   this.scale+=0.1;
                   // console.log(el.offsetWidth,el.offsetWidth * this.scale);
                   // let elemRect = el.getBoundingClientRect();
                   // console.log(elemRect);
                   el.style.position = 'relative';
                   if(this.scale > 1){
                       el.style.top = ((el.offsetHeight * this.scale) - el.offsetHeight)+ 'px';
                       el.style.left = ((el.offsetWidth * this.scale) - el.offsetWidth) + 'px';
                   } else {
                       el.style.top = '0px';
                       el.style.left = '0px';
                   }

                   // el.style.width = (el.offsetWidth * this.scale).toFixed(2) + 'px';
                   // el.style.height = (el.offsetHeight * this.scale).toFixed(2) + 'px';
                   el.style.transform = 'scale(' + this.scale + ')';
               }
            },
            scaleDown() {
               let el = this.$refs['scaled-wrap'];
                if(this.scale > 0.1) {
                    this.scale-=0.1;
                    el.style.position = 'relative';
                    if(this.scale > 1){
                        el.style.top = ((el.offsetHeight * this.scale) - el.offsetHeight)+ 'px';
                        el.style.left = ((el.offsetWidth * this.scale) - el.offsetWidth) + 'px';
                    } else {
                        el.style.top = '0px';
                        el.style.left = '0px';
                    }

                    // el.style.width = (el.offsetWidth * this.scale).toFixed(2) + 'px';
                    // el.style.height = (el.offsetHeight * this.scale).toFixed(2) + 'px';
                    el.style.transform = 'scale(' + this.scale + ')';
                }
            },
        }


    }
</script>

<style>
    #structure-draggable{
        overflow: auto;
        height: calc(100vh - 280px);
    }
    #structure-draggable .structure-item[data-level="1"] {
        /*background-color: #f5f9fc;*/
        border-color: #c8d7e4;
        height: 109px;
    }
    #structure-draggable .structure-item[data-level="2"] {
        /*background-color: #f8efe8;*/
        border-color: #ebcab5;

    }
    /*#structure-draggable .structure-item[data-level="2"] .department-name{*/
        /*color:#da9365;*/
    /*}*/
    #structure-draggable .structure-item[data-level="3"] {
        /*background-color: #f1f5de;*/
        border-color: #d5e1a6;
        min-width: 182px;
        width: 182px;
        margin-left: 30px;
    }
    /*#structure-draggable .structure-item[data-level="3"] .department-name{*/
        /*color:#a7b95d;*/
    /*}*/
    #structure-draggable .structure-dept-title {
        position: relative;
        font-size: 13px;
        font-weight: bold;
        color: #2067b0;
        border: none;
    }
    #structure-draggable .structure-dept-title-text {
        padding: 8px 11px 3px 10px;
        max-width: 186px;
        /*text-transform: uppercase;*/
        line-height: 14px;
        font-size: 12px;
        height: 48px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }
    #structure-draggable .structure-dept-second .structure-dept-title-text, .structure-dept-first .structure-dept-title-text {
        max-width: 190px;
    }
    #structure-draggable .structure-dept-emp-popup a, .structure-dept-block a {
        text-decoration: none;
        color: #2067b0;
    }
    #structure-draggable .structure-dept-first .structure-icon-box {
        width: 35px;
        background-color: #f5f9fc;
    }
    #structure-draggable .structure-dept-top .structure-edit-icon {
        right: 18px;
    }
    #structure-draggable .structure-dept-top .structure-icon-box .structure-delete-icon {
        display: none;
    }
    #structure-draggable .structure-dept-top .structure-add-icon {
        right: 2px;
    }
    #structure-draggable .structure-dept-first .structure-edit-icon, .structure-dept-first .structure-delete-icon, .structure-dept-first .structure-add-icon {
        background-color: #f8fbfd;
    }
    #structure-draggable .structure-boss-block {
        height: 30px;
        margin: 8px 8px 0 9px;
        position: relative;
        display: none;
    }
    #structure-draggable .structure-designate-text {
        background: #fff;
        border: 1px dashed;
        color: #6c6c6c;
        display: none;
        font-size: 11px;
        font-weight: bold;
        height: 22px;
        left: -2px;
        right: -2px;
        top: -1px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 7px 0 0 28px;
        position: absolute;
        white-space: nowrap;
    }
    #structure-draggable .structure-designate-text i {
        background: url(./images/structure_company_sprite.png) no-repeat 0 -152px;
        height: 17px;
        display: block;
        position: absolute;
        width: 17px;
    }
    #structure-draggable .structure-employee-block {
        height: 55px;
        margin: 5px 8px 1px 8px;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    #structure-draggable .structure-employee-title {
        color: #86a5c4;
        font-size: 10px;
        height: 11px;
        padding: 0 0 1px 3px;
    }
    #structure-draggable .structure-employee-list {
        margin: 0 -3px;
        /*overflow: hidden;*/
        height: 31px;
        padding: 0 3px;
    }
    #structure-draggable .structure-dept-editable .structure-avatar {
        cursor: move;
    }
    #structure-draggable .ui-icon[class*=ui-icon-common-]>i {
        background-color: #7b8691;
    }
    #structure-draggable .ui-icon-common-user > i {
        background-image: url(./images/ui-user.svg);
    }
    #structure-draggable .structure-employee-block .structure-avatar {
        display: inline-block;
        /*margin: 3px 1px;*/
        position: static;
        vertical-align: middle;
        margin: 6px 1px;
    }
    #structure-draggable .structure-more-employee, .structure-more-employee:link, .structure-more-employee:active {
        color: #2067b0;
        display: block;
        /*font-size: 10px;*/
        text-align: center;
        margin-top: auto;
    }

    #structure-draggable a.structure-more-employee:hover{
        text-decoration: underline;
    }

    #structure-draggable .structure-designate-text {
        background: #fff;
        border: 1px dashed;
        color: #6c6c6c;
        display: none;
        font-size: 11px;
        font-weight: bold;
        height: 22px;
        left: -2px;
        right: -2px;
        top: -1px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 7px 0 0 28px;
        position: absolute;
        white-space: nowrap;
    }

    #structure-draggable .structure-designate-text i {
        background: url(./images/structure_company_sprite.png) no-repeat 0 -152px;
        height: 17px;
        display: block;
        position: absolute;
        width: 17px;
    }
    #structure-draggable .structure-add-dept-text {
        height: 65px;
        left: -1px;
        right: -1px;
        padding: 40px 0 0 45px;
        top: 29px;
    }
    #structure-draggable .structure-add-employee.structure-dept-first .structure-designate-text, .structure-dept-first .structure-add-dept-text {
        border-color: #79afc5;
    }
    #structure-draggable td.bx-str-top {
        vertical-align: top;
        /*background-image: url(../images/line_blue.png);*/
        background-repeat: repeat-y;
        background-position: center center;
        padding: 0 10px 20px 10px;
        background-position: center 60px;
    }

    #structure-draggable .structure-dept-editable:hover .structure-icon-box, .structure-dept-editable:hover .structure-delete-icon, .structure-dept-editable:hover .structure-add-icon, .structure-dept-editable:hover .structure-edit-icon {
        display: block;
    }
    #structure-draggable  .structure-dept-top .structure-edit-icon {
        right: 18px;
    }
    #structure-draggable .structure-dept-top .structure-add-icon {
        right: 2px;
    }
    #structure-draggable  .ui-icon>i {
        position: relative;
        display: block;
        padding-top: 100%;
        width: 100%;
        border-radius: 50%;
        background-color: #ebeff2;
        background-position: center;
        background-size: 100% auto;
        background-repeat: no-repeat;
    }
    #structure-draggable .structure-avatar {
        position: absolute;
        left: 0;
        top: 2px;
        height: 23px;
        width: 23px;
        border-radius: 100%;
        background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20viewBox%3D%220%200%2089%2089%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Ctitle%3Euserpic%3C/title%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Ccircle%20fill%3D%22%23535C69%22%20cx%3D%2244.5%22%20cy%3D%2244.5%22%20r%3D%2244.5%22/%3E%3Cpath%20d%3D%22M68.18%2071.062c0-3.217-3.61-16.826-3.61-16.826%200-1.99-2.6-4.26-7.72-5.585-1.734-.483-3.383-1.233-4.887-2.223-.33-.188-.28-1.925-.28-1.925l-1.648-.25c0-.142-.14-2.225-.14-2.225%201.972-.663%201.77-4.574%201.77-4.574%201.252.695%202.068-2.4%202.068-2.4%201.482-4.3-.738-4.04-.738-4.04.388-2.625.388-5.293%200-7.918-.987-8.708-15.847-6.344-14.085-3.5-4.343-.8-3.352%209.082-3.352%209.082l.942%202.56c-1.85%201.2-.564%202.65-.5%204.32.09%202.466%201.6%201.955%201.6%201.955.093%204.07%202.1%204.6%202.1%204.6.377%202.556.142%202.12.142%202.12l-1.786.217c.024.58-.023%201.162-.14%201.732-2.1.936-2.553%201.485-4.64%202.4-4.032%201.767-8.414%204.065-9.193%207.16-.78%203.093-3.095%2015.32-3.095%2015.32H68.18z%22%20fill%3D%22%23FFF%22/%3E%3C/g%3E%3C/svg%3E);
        background-repeat: no-repeat;
        overflow: hidden;
    }
    #structure-draggable  .structure-edit-icon {
        right: 34px;
        background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2013%2013%22%3E%3Cpath%20fill%3D%22%23525C69%22%20d%3D%22M9.811.236l2.348%202.348L3.737%2011%201.389%208.652%209.811.236zM.005%2012.073a.261.261%200%200%200%20.066.249c.065.066.16.091.249.066l2.784-.75L.755%209.29l-.75%202.783z%22%20opacity%3D%22.7%22/%3E%3C/svg%3E);
    }
    #structure-draggable .structure-icon-box {
        display: none;
        position: absolute;
        top: 9px;
        right: 0;
        width: 50px;
        height: 14px;
    }
    #structure-draggable .structure-edit-icon, .structure-delete-icon, .structure-add-icon {
        position: absolute;
        height: 13px;
        width: 13px;
        background-repeat: no-repeat;
        transition: opacity .3s;
        opacity: 1;
        cursor: pointer;
    }

    #structure-draggable .structure-add-icon {
        right: 18px;
        background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2013%2012%22%3E%3Cpath%20fill%3D%22%23525C69%22%20d%3D%22M7.438%205V3h-2v2h-2v2h2v2h2V7h2V5h-2zm-1%207a6%206%200%201%201%200-12%206%206%200%200%201%200%2012z%22%20opacity%3D%22.7%22/%3E%3C/svg%3E);
    }

    #structure-draggable .structure-item{
        position: relative;
        margin: 20px 10px;
        display: inline-block;
        vertical-align: top;
        min-width: 205px;
        width: 205px;
        background-color: #fbfbfb;
        border: 1px solid #ebe7e7 !important;
        border-radius: 2px;
        /*border-color: #f3f3f3;*/
        border-left: 4px solid;
        box-sizing: border-box;
        height: 109px;
        box-shadow: 1px 1px 5px 0px #8080803d;
    }

    #structure-draggable .structure-item.bx-middle-connect:after {
        left: calc(50% - 0px);
        position: absolute;
        content: '';
        height: 14px;
        width: 0px;
        border-left: 2px dashed #c6c6c6;
        padding: 0;
    }
    #structure-draggable .structure-item.bx-right-connect:after {
        left: -15px;
        top: -20px;
        position: absolute;
        border-top: 2px dashed #c6c6c6;
        border-right: 2px dashed #c6c6c6;
        /*border-radius: 0 3px 0 0;*/
        content: '';
        height: 14px;
        width: calc(50% + 14px);
    }
    #structure-draggable .dep-wrap {
        text-align: right;
    }
    #structure-draggable .dep-wrap > section{
        text-align: left;
    }
    .dep-wrap .sub-structure-parent{
        margin-top: -8px;
    }
    #structure-draggable .sub-structure-parent .structure-item{
        margin: 12px 10px 12px 30px;
    }
    #structure-draggable .sub-structure-parent .structure-overflow .structure-item{
        margin: 18px 10px 18px 11px;
    }
    #structure-draggable .structure-item.bx-right-connect-long:after {
        left: calc(-50% - 23px);
        top: -20px;
        position: absolute;
        border-top: 2px dashed #c6c6c6;
        border-right: 2px dashed #c6c6c6;
        /*border-radius: 0 3px 0 0;*/
        content: '';
        height: 14px;
        width: calc(100% + 22px);
    }
    #structure-draggable .structure-item.bx-left-connect:after {
        left: 50%;
        top: -20px;
        position: absolute;
        border-top: 2px dashed #c6c6c6;
        border-left: 2px dashed #c6c6c6;
        /*border-radius: 3px 0 0 0;*/
        content: '';
        height: 14px;
        width: calc(50% + 14px);
    }
    #structure-draggable .structure-item.bx-left-connect-long:after {
        left: calc(50%);
        top: -20px;
        position: absolute;
        border-top: 2px dashed #c6c6c6;
        border-left: 2px dashed #c6c6c6;
        /*border-radius: 3px 0 0 0;*/
        content: '';
        height: 14px;
        width: calc(100% + 19px);
    }
    #structure-draggable .structure-item[data-level="3"]:after{
        left: -20px;
        top: -22px;
        position: absolute;
        border-bottom: 2px dashed #c6c6c6;
        border-left: 2px dashed #c6c6c6;
        /*border-radius: 0 0 0 3px;*/
        content: '';
        height: calc(50% + 23px);
        width: 14px;
    }
    #structure-draggable .structure-item[data-level="3"].bx-vert-connect-long:after{
        top: calc(-50% - 28px);
        height: calc(100% + 28px);
    }
    #structure-draggable .structure-show-nested{
        transform: rotate(90deg);
        position: absolute;
        right: -31px;
        top: 4px;
        background: #bbcd86;
        border-radius: 1px;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        margin-top: 6px;
        font-size: 9px;
        line-height: 14px;
        padding: 0 5px;
    }
    #structure-draggable .show-nested-wrap{
        display: flex;
        margin-top: -13px;
        top: 37px;
        width: 100%;
        position: absolute;
        justify-content: flex-end;
    }
    #structure-draggable .structure-employee-wrap{
        width: 160px;
        height: 25px;
        line-height: 25px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }
    #structure-draggable .depart-wrap{
        display: flex;
        flex-wrap: wrap;
    }
    #structure-draggable .structure-overflow{
        padding: 0 14px;
        position:absolute;
        top:0;
        background-color: #8e8989;
    }
    #structure-draggable .close-structure-overflow{
        color: white;
        float: right;
        margin-right: -7px;
        font-size: 18px;
        margin-bottom: -12px;
        cursor: pointer;
    }
    #structure-draggable .leader-block-info{
        font-size: 11px;
        color: #2dbb2d;
        margin-bottom: 0;
        padding-top: 8px;
        height: 20px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }
    #structure-draggable .leader-data{
        color: black;
    }
    .main-department .dep-wrap{
            display: flex;
            justify-content: center;
            position: relative;
            left: -3px;
    }
    .structure-overflow .main-department .dep-wrap{
            left: 0;
    }
    .dragging-department > section:before,
    .dragging-department > section:after{
        display: none;
    }
    .dragging-department > section{
        margin-top: 0;
        margin-bottom: 0;
    }
    .dragging-department{
        cursor:move;
        /*opacity:1 !important;*/
        background-color: #fff !important;
    }
    #structure-draggable .dropping-department{
        border: 2px dashed red !important;
        transition: all 0.3s;
    }
</style>
